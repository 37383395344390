import React, { useEffect } from 'react';
import Section from '../Section';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import { pageTitle } from '../../helpers/PageTitle';
import AboutSectionStyle3 from '../Section/AboutSection/AboutSectionStyle3';
import Cta from '../Cta';
import { useLocation } from 'react-router-dom';



const teamData = [
  {
    imgUrl: 'images/about/alisa.jpg',
    name: 'Alisa Lanzetta ',
    designation: 'Owner',
    credentials: 'BCBA, NYS LBA ',
    background: ['Founded Starbright after 15 years working as a special education teacher with Applied Behavior Analysis as her specialty. In 2010 she became a Board Certified Behavior Analyst and in 2014 a licensed behavior analyst.',
    ],
    interests:'',
  },
  {
    imgUrl: 'images/about/joanne.jpg',
    name: 'Joanne Collins',
    designation: 'Human Resources Director',
    credentials:'',
    background:['Joanne Collins is a seasoned professional with over 25 years of experience in business and human resources. Holding both a Master of Business Administration (MBA) and a Master of Arts in Teaching with a focus in Educational Leadership, Joanne combines her expertise in business management with a passion for education. Throughout her career, she has developed a deep commitment to helping children thrive in educational settings, guiding them to build confidence and succeed academically. Joanne is dedicated to fostering environments where young learners can grow into prosperous, joyful individuals who are equipped to live fulfilling lives. Her dedication to both business and education reflects her belief in the power of knowledge and confidence to shape future success.',
    ],
    interests: ''
  },
  {
    imgUrl: 'images/about/annie.jpg',
    name: 'Annie Chang ',
    designation: 'Patient Coordinator',
    credentials:'',
    background: ['Background in both Human Resources and teaching. Her HR experience enables her to efficiently manage patient intake, scheduling, and streamline the onboarding process, while her teaching background helps her connect with families and communicate complex information with clarity and empathy.',
    ],
    interests:
      'Outside of work, Annie enjoys various arts and crafts projects. She resides in Monroe with her husband, two children, and their dog, where she enjoys spending time with her family and engaging in traveling and creative hobbies.',
    
  },
  
  {
    imgUrl: 'images/about/crissy.jpg',
    name: 'Crissy Wang',
    designation: 'Senior Supervising BCBA',
    credentials:'BCBA, NYS LBA',
    background:['Crissy serves as the Senior Supervising BCBA at Starbright ABA, where she leverages a decade of experience as a Board Certified Behavior Analyst (BCBA) and New York State Licensed Behavior Analyst (NYS LBA). Throughout her career, she has worked with individuals across all age groups in diverse settings, including homes and public schools, with a particular passion for her current role in the clinic at Starbright.',
      'With a robust background in early childhood and special education, Crissy brings over 20 years of experience to her position. Her approach is characterized by compassion and a commitment to providing individualized interventions that prioritize each child\'s unique needs and strengths, empowering them to reach their full potential.',
       'Crissy has consistently collaborated with families and multidisciplinary teams to implement effective strategies that promote learning and foster autonomy for her clients. Her dedication to supporting both children and their families is central to her practice.'
      ],
    interests: 'Outside of her professional commitments, Crissy enjoys being a mother to her two sons, reading, watching basketball, and taking walks with her dog.'
  },
  
  {
    imgUrl: 'images/about/Amber.jpg',
    name: 'Amber Abrahamsen',
    designation: 'Board-Certified Behavior Analyst',
    credentials:'BCBA, NYS LBA',
    background:['Amber\'s journey in Applied Behavior Analysis began as an RBT at StarBright ABA in 2019. She loved it so much, she was quickly inspired to pursue a career in ABA. She earned her master’s degree in Applied Behavior Analysis and Autism in 2022 from Russell Sage College and obtained her BCBA and LBA certifications in early 2023. Her experience has been enriched by working extensively at StarBright ABA, allowing her to gain in-depth knowledge, continuity with clients, and a strong foundation in best practices. Known for her bubbly personality and warm approach, Amber fosters a supportive, positive environment that encourages each client to thrive. Her ability to connect with clients and families creates strong rapport, making each session both impactful and enjoyable.',
    'Dedicated to supporting individuals with autism, she is committed to client-centered, individualized care and collaborates closely with families, caregivers, and multidisciplinary teams to ensure lasting and meaningful progress for every client.'],
    interests: 'When she\'s not at work, Amber enjoys working out, exploring new places, paddleboarding, and hiking.'
  },
  {
    imgUrl: 'images/about/Kerri.jpg',
    name: 'Kerri Merritt',
    designation: 'Board-Certified Behavior Analyst',
    credentials:'BCBA, NYS LBA',
    background:['Kerri worked in childcare for five years where she realized her passion for working with children. From there, she pursued a masters degree in Applied Behavior Analysis from Purdue University Global graduating in late 2022. Upon starting her schooling, Kerri acquired a position as a 1:1 instructional paraprofessional at an ABA school for children with autism in New Jersey where she worked for three years while finishing her masters program. She received her BCBA and LBA certifications in 2024 and since has worked in home services and the public school setting.',
    'Having worked in various settings, collaboration with parents, educators and other professionals and the commitment to delivering quality care to clients has always been the heart of Kerri’s work.'],
    interests: 'Kerri is a mom of two kids who love to go to the movies, build Lego and go to water parks together. She enjoys spending time outside at the local parks and spending time with extended family.'
  },
  {
    imgUrl: 'images/about/Sofia.jpg',
    name: 'Sofia Rocco',
    designation: 'Board-Certified Behavior Analyst',
    credentials:'BCBA',
    background:['Passionate about empowering individuals through the science of ABA, Sofia is a Board Certified Behavior Analyst with a heart for helping others. All of her experience has been shaped at Starbright ABA, where they’ve honed their skills in creating positive, meaningful change while building strong, compassionate relationships with clients and families.',
      'Whether celebrating small victories or navigating challenges, Sofia is dedicated to making a lasting impact, fostering a supportive and inclusive environment for growth, and always putting people first.',
    ],
    interests: ''
  },
];

const galleryData = [
  { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
];


export default function About() {

  const location = useLocation();

  useEffect(() => {
    console.log("useEffect triggered, hash:", location.hash);
  
    if (location.hash) {
      setTimeout(() => {
        const section = document.querySelector(location.hash);
        if (section) {
          console.log("Found section after timeout:", section);
          section.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log("No section found for:", location.hash);
        }
      }, 100); // Adjust this delay as necessary
    }
  }, [location]);
  
  
  
  
  pageTitle('About');
  return (
    <>
      
      <Section bottomMd={95} bottomLg={70} bottomXl={50}>
        <AboutSectionStyle3
          titleUp="MISSION STATEMENT"
          title="Helping children develop to their fullest potential"
          subTitle="Our mission is to treat young children with developmental delays and emerging social concerns in the earliest months and years of life, in order to give every child we serve the chance to develop to his or her fullest potential and gain the greatest success and enjoyment of learning and life."
          imgUrl="/images/about/girls-having-fun.jpg"
        />
      </Section>
      
      <Section topMd={95} topLg={70} topXl={50}>
        <TeamSection
          sectionTitle="Wonderful Team"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={95} topLg={70} topXl={50} id="cta-section">
        <Cta
          title="Want to Join Our Wonderful Team?"
          subTitle="Email us your resume at info@starbrightaba.com"
          bgUrl="/images/about/cta-bg.jpg"
          btnUrl="mailto:info@starbrightaba.com"
          btnText="Email Us"
          featuredImg="/images/about/teamfun.jpg"
          imgCaption="Team night out!"
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80} bottomMd={95} bottomLg={70} bottomXl={50}>
        <GallerySection
          sectionTitle="Our Therapy and <br />Learning Spaces"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      
    </>
  );
}
